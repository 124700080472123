












































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  AlertMessage,
  SurveyFieldAttributes,
  SurveyResource,
} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {surveyFieldModule, surveyModule} from '@/store/modules';
import SurveyFieldTypeAutocomplete from '@/components/surveys-edit/SurveyFieldTypeAutocomplete.vue';
import Dialog from '@/components/ui/Dialog.vue';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import {rulesRequired, urlFriendly} from '@/utility';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {QuestionType} from '@/enums';
import {surveyFieldItemModule} from '@/store/modules/surveyFieldItemModule';
import DropdownChainAutocomplete from '@/components/surveys-edit/DropdownChainAutocomplete.vue';
import SurveyPageAutocomplete from '@/components/surveys-edit/SurveyPageAutocomplete.vue';
import FileUploadAllowedTypesAutocomplete from '@/components/surveys-edit/FileUploadAllowedTypesAutocomplete.vue';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import DialogAlertDense from '@/components/ui/DialogAlertDense.vue';
import SurveyFieldDialogItemList from '@/components/surveys-edit/SurveyFieldDialogItemList.vue';
import DisplayControlAutocomplete from '@/components/surveys-edit/DisplayControlAutocomplete.vue';
import DateTimeRangeQuestion from '@/components/questions/DateTimeRangeQuestion.vue';
import DateRangeQuestion from '@/components/questions/DateRangeQuestion.vue';
import {isString} from 'lodash';

@Component({
  components: {
    DateRangeQuestion,
    DateTimeRangeQuestion,
    DisplayControlAutocomplete,
    SurveyFieldDialogItemList,
    DialogAlertDense,
    NotificationBar,
    FileUploadAllowedTypesAutocomplete,
    SurveyPageAutocomplete,
    DropdownChainAutocomplete,
    RequiredLabel,
    DialogAlert,
    Dialog,
    SurveyFieldTypeAutocomplete,
  },
})
export default class SurveyFieldDialog extends Vue {
  @Prop() private fieldId!: string;
  @Prop() private surveyId!: string;
  @Prop({default: false}) private newItem!: false;
  private alert: AlertMessage | null = null;
  private rulesRequired = rulesRequired;
  private nameTouched = false;
  private typeTouched = false;
  private questionType = QuestionType;
  private valid = false;
  private isString = isString;

  get survey(): SurveyResource {
    return surveyModule.find(this.surveyId);
  }

  get busy(): boolean {
    return surveyFieldModule.busy || surveyFieldItemModule.busy;
  }

  get deleting(): boolean {
    return surveyFieldModule.deleting;
  }

  get hasItems(): boolean {
    switch (this.surveyField.type) {
      case QuestionType.Dropdown:
      case QuestionType.CheckboxGroup:
      case QuestionType.RadioGroup:
      case QuestionType.RatingRadio:
        return true;
      default:
        return false;
    }
  }

  get loading(): boolean {
    return surveyFieldModule.loading;
  }

  get saving(): boolean {
    return surveyFieldModule.saving || surveyFieldItemModule.saving;
  }

  get surveyField(): SurveyFieldAttributes {
    return surveyFieldModule.find(this.fieldId).attributes;
  }

  async created(): Promise<void> {
    try {
      await surveyFieldModule.loadById(this.fieldId);
      // try to load items regardless of field tyoe just in case user switxhes tyoes

      // await surveyFieldItemModule.loadMulti({
      //   filters: {surveyFieldId: this.fieldId},
      // });
      if (this.surveyField.name) {
        this.nameTouched = true;
      }
      if (this.surveyField.type && !this.newItem) {
        this.typeTouched = true;
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.error(err.response.data);
      } else {
        console.error(err);
      }
    }
  }

  async save(): Promise<void> {
    if (this.hasItems) {
      await this.saveItems();
    }
    try {
      await surveyFieldModule.save(this.fieldId);

      this.$emit('close', 'Field saved successfully');
    } catch (err) {
      console.error(err);
      this.alert = {message: 'Could not save field', type: 'error'};
    }
  }

  async cancel() {
    if (this.newItem) {
      await surveyFieldModule.delete(this.fieldId);
    } else {
      // reload the field and items in case they were changed
      await Promise.all([
        surveyFieldModule.loadById(this.fieldId),
        surveyFieldItemModule.loadMulti({
          filters: {surveyFieldId: this.fieldId},
        }),
      ]);
    }
    this.$emit('close');
  }

  deleteItem(itemId: string) {
    this.surveyField.items = Object.values(this.surveyField.items).filter(
      item => String(item.id) !== String(itemId)
    );
  }

  async saveItems() {
    for (const item of Object.values(this.surveyField.items)) {
      const itemId = String(item.id);
      try {
        await surveyFieldItemModule.save(itemId);
        this.alert = null;
      } catch (err) {
        console.error(err);
        const error = err.response?.data?.errors[0]?.detail;
        this.alert = {
          message: error ? error : 'Error',
          type: 'error',
        };
      }
    }
  }

  generateName(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    const name = urlFriendly(value);
    //console.log(event.target.value, name);
    if (!this.nameTouched) {
      this.surveyField.name = name;
    }
  }
}
