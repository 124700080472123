























































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import SurveyPageAutocomplete from '@/components/surveys-edit/SurveyPageAutocomplete.vue';
import {
  EditableFieldSaveEvent,
  Snackbar,
  SurveyPageResource,
} from '@/interfaces';
import SurveyFields from '@/components/surveys-edit/SurveyFields.vue';
import {Prop} from 'vue-property-decorator';
import {surveyPageModule} from '@/store/modules';
import {
  mdiDotsVertical,
  mdiSort,
  mdiDragHorizontal,
  mdiPlus,
  mdiTrashCan,
} from '@mdi/js';
import EditableField from '@/components/ui/EditableField.vue';
import draggable from 'vuedraggable';
import {orderBy} from 'lodash';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import {rulesRequired} from '@/utility';
import InfoTooltip from '@/components/ui/InfoTooltip.vue';

@Component({
  components: {
    InfoTooltip,
    NotificationBar,
    SurveyPageAutocomplete,
    EditableField,
    SurveyFields,
    draggable,
  },
})
export default class SurveyPages extends Vue {
  @Prop() surveyId!: string;
  private iconAdd = mdiPlus;
  private iconDelete = mdiTrashCan;
  private iconMore = mdiDotsVertical;
  private iconDrag = mdiDragHorizontal;
  private iconReorder = mdiSort;
  private savingPageId: string | null = null;
  private snackbar: Snackbar = {visible: false, message: ''};
  private rulesRequired = rulesRequired;

  get busy(): boolean {
    return surveyPageModule.busy;
  }

  get deleting(): boolean {
    return surveyPageModule.deleting;
  }

  get deletingIds(): string[] {
    return surveyPageModule.deletingIds;
  }

  get listing(): boolean {
    return surveyPageModule.listing;
  }

  get saving(): boolean {
    return surveyPageModule.saving;
  }

  get savingIds(): string[] {
    return surveyPageModule.savingIds;
  }

  get surveyPages(): SurveyPageResource[] {
    return orderBy(surveyPageModule.list, ['attributes.order']).filter(
      sp => sp.id !== 'new'
    );
  }

  set surveyPages(surveyPages: SurveyPageResource[]) {
    surveyPageModule.reorder(surveyPages);
  }

  getSurveyPageById(pageId: string) {
    return surveyPageModule.find(pageId).attributes;
  }

  async created() {
    try {
      await surveyPageModule.loadMulti({
        filters: {surveyId: this.surveyId},
      });
    } catch (err) {
      console.error(err.response.data);
    }
  }

  async addPage(): Promise<void> {
    const surveyPage = await surveyPageModule.loadById('new');
    surveyPage.attributes.surveyId = parseInt(this.surveyId);
    surveyPage.attributes.label = 'New Page';
    await surveyPageModule.save('new');
  }

  async deletePage(id: string): Promise<void> {
    await surveyPageModule.delete(id);
  }

  async save({id}: EditableFieldSaveEvent) {
    this.savingPageId = id;
    await surveyPageModule.save(id);
  }

  async savePage(id: string): Promise<void> {
    this.savingPageId = id;
    await surveyPageModule.save(id);
  }

  async pageMoved(): Promise<void> {
    const orderedIds = this.surveyPages.map(page => page.id);

    await surveyPageModule.action({
      attributes: {action: 'reorder', surveyId: this.surveyId, ids: orderedIds},
    });
    this.snackbar.visible = true;
    this.snackbar.message = 'Survey page order saved.';
  }
}
