var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%","outlined":"","elevation":"2","loading":_vm.listing}},[_c('v-card-title',{staticClass:"subheading font-weight-bold"},[_vm._v(" Survey Fields ")]),_c('v-card-text',[(_vm.surveyPages.length > 0)?_c('v-expansion-panels',{staticClass:"mb-10",attrs:{"multiple":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('v-icon',[_vm._v(_vm._s(_vm.iconReorder))]),_vm._v(" Reorder Survey Pages ")],1)]),_c('v-expansion-panel-content',[_c('v-list',[_c('draggable',{attrs:{"group":"pages"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":_vm.pageMoved},model:{value:(_vm.surveyPages),callback:function ($$v) {_vm.surveyPages=$$v},expression:"surveyPages"}},_vm._l((_vm.surveyPages),function(page){return _c('v-list-item',{key:page.id,staticClass:"survey-page"},[_c('v-list-item-icon',{staticClass:"drag"},[_c('v-icon',[_vm._v(_vm._s(_vm.iconDrag))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(page.attributes.label)+" ")])],1)],1)}),1)],1)],1)],1)],1):_vm._e(),(_vm.listing)?_c('v-skeleton-loader',{attrs:{"type":"table-row@5"}}):_vm._e(),(_vm.surveyPages.length === 0 && !_vm.listing)?_c('p',{staticClass:"text-center"},[_c('em',[_vm._v("This survey doesn't have any pages yet. Use the Add Page button below to create your first page.")])]):_vm._e(),_vm._l((_vm.surveyPages),function(page){return _c('v-card',{key:page.id,staticClass:"mb-10",attrs:{"loading":_vm.listing,"outlined":"","elevation":"2"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('EditableField',{attrs:{"required":true,"value":page.attributes.label,"saving":_vm.saving && _vm.savingIds.includes(page.id),"id":page.id},on:{"save":_vm.save,"update:value":function($event){return _vm.$set(page.attributes, "label", $event)}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('v-text-field',{attrs:{"outlined":"","required":true,"rules":_vm.rulesRequired,"label":"Page Title","value":value},on:{"input":input}})]}},{key:"display",fn:function(){return [(page.attributes.label)?_c('span',[_vm._v(_vm._s(page.attributes.label))]):_c('em',{staticClass:"text-muted"},[_vm._v("No value")])]},proxy:true}],null,true)}),_c('v-switch',{staticClass:"mr-3",attrs:{"outlined":"","loading":_vm.saving && _vm.savingIds.includes(page.id),"disabled":_vm.saving && _vm.savingIds.includes(page.id)},on:{"change":function($event){return _vm.savePage(page.id)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Continue to next page "),_c('span',{staticClass:"ml-1"},[(page.attributes.continue)?_c('info-tooltip',[_vm._v("Upon completion of this page the user will be directed to the next page in this survey.")]):_c('info-tooltip',[_vm._v("Upon completion of this page the survey will be completed and the user will be directed to a thank you page.")])],1)]},proxy:true}],null,true),model:{value:(page.attributes.continue),callback:function ($$v) {_vm.$set(page.attributes, "continue", $$v)},expression:"page.attributes.continue"}}),(page.attributes.continue)?_c('div',[_c('strong',{staticStyle:{"font-size":"12px","line-height":"14px"}},[_vm._v("Next page:")]),_c('EditableField',{attrs:{"required":false,"value":page.attributes.nextPageId,"saving":_vm.saving && _vm.savingIds.includes(page.id),"id":page.id},on:{"save":_vm.save,"update:value":function($event){return _vm.$set(page.attributes, "nextPageId", $event)}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('SurveyPageAutocomplete',{attrs:{"value":value,"label":"Select page"},on:{"input":input}})]}},{key:"display",fn:function(){return [(page.attributes.nextPageId)?_c('span',{staticStyle:{"font-size":"12px","line-height":"14px"}},[_vm._v(_vm._s(_vm.getSurveyPageById(page.attributes.nextPageId).label))]):_c('em',{staticClass:"text-muted",staticStyle:{"font-size":"12px","line-height":"14px"}},[_vm._v("None - next page will be next page below")])]},proxy:true}],null,true)})],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconMore))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"loading":_vm.deleting && _vm.deletingIds.includes(page.id),"disabled":_vm.deleting && _vm.deletingIds.includes(page.id)},on:{"click":function($event){return _vm.deletePage(page.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconDelete))]),_vm._v(" Delete Page")],1)],1)],1)],1)],1)],1),_c('v-card-text',[_c('SurveyFields',{attrs:{"pageId":page.id,"surveyId":_vm.surveyId}})],1)],1)})],2),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"accent","disabled":_vm.busy && _vm.savingIds.includes('new'),"loading":_vm.saving && _vm.savingIds.includes('new')},on:{"click":_vm.addPage}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconAdd))]),_vm._v(" Add page")],1)],1),_c('NotificationBar',{attrs:{"snackbar":_vm.snackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }