







































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  AlertMessage,
  Snackbar,
  SurveyFieldResource,
  SurveyResource,
} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import SurveyFieldDialog from '@/components/surveys-edit/SurveyFieldDialog.vue';
import {surveyFieldModule} from '@/store/modules/surveyFieldModule';
import SurveyField from '@/components/surveys/SurveyField.vue';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import {
  mdiArrowULeftTop,
  mdiDotsVertical,
  mdiDragHorizontal,
  mdiPlus,
  mdiTrashCan,
} from '@mdi/js';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import QuestionCollectionAddToSurveyDialog from '@/components/surveys-edit/QuestionCollectionAddToSurveyDialog.vue';
import draggable from 'vuedraggable';
import {orderBy} from 'lodash';
import IconSurveyFieldJump from '@/components/surveys-edit/IconSurveyFieldJump.vue';
import {isIntegrated} from '@/utility';
import {surveyModule} from '@/store/modules';
import IconSurveyFieldRepeatable from '@/components/surveys-edit/IconSurveyFieldRepeatable.vue';
import IconQuestionCollectionRepeatable from '@/components/surveys-edit/IconQuestionCollectionRepeatable.vue';

@Component({
  components: {
    IconQuestionCollectionRepeatable,
    IconSurveyFieldRepeatable,
    IconSurveyFieldJump,
    QuestionCollectionAddToSurveyDialog,
    SurveyFieldDialog,
    SurveyField,
    ButtonEditAction,
    ButtonDeleteAction,
    NotificationBar,
    draggable,
  },
})
export default class SurveyFields extends Vue {
  @Prop() private pageId!: string;
  @Prop() private surveyId!: string;
  private alert: AlertMessage | null = null;
  private fieldId: string | null = null;
  private iconAdd = mdiPlus;
  private iconDrag = mdiDragHorizontal;
  private iconMore = mdiDotsVertical;
  private iconDelete = mdiTrashCan;
  private iconRestore = mdiArrowULeftTop;
  private isIntegrated = isIntegrated;
  private loading = false;
  private newField = false;

  private questionCollectionAddToSurveyDialogShown = false;
  private snackbar: Snackbar = {visible: false, message: ''};
  private surveyFieldDialogShown = false;

  get busy(): boolean {
    return surveyFieldModule.busy;
  }

  get getting(): boolean {
    return surveyFieldModule.getting;
  }

  get deleting(): boolean {
    return surveyFieldModule.deleting;
  }

  get deletingIds(): string[] {
    return surveyFieldModule.deletingIds;
  }

  get listing(): boolean {
    return surveyFieldModule.listing;
  }

  get saving(): boolean {
    return surveyFieldModule.saving;
  }

  get surveyFields(): SurveyFieldResource[] {
    return orderBy(surveyFieldModule.list, ['attributes.order'])
      .filter(field => field.id?.substr(0, 3) !== 'new')
      .filter(field => String(field.attributes.pageId) === this.pageId)
      .filter(field => !field.attributes.questionId);
  }

  set surveyFields(surveyFields: SurveyFieldResource[]) {
    surveyFieldModule.reorder(surveyFields);
  }

  get surveyFieldsAll(): SurveyFieldResource[] {
    return orderBy(surveyFieldModule.list, ['attributes.order'])
      .filter(field => field.id?.substr(0, 3) !== 'new')
      .filter(field => String(field.attributes.pageId) === this.pageId);
  }

  // get surveyFieldsQ(): SurveyFieldResource[] {
  //   const fields: SurveyFieldResource[] = [];
  //   let inQC = false;
  //   this.surveyFields.forEach(field => {
  //     if (!field.attributes.questionId) {
  //       fields.push(field);
  //       inQC = false;
  //     } else if (!inQC && field.attributes.questionId) {
  //       inQC = true;
  //       fields.push(field);
  //     }
  //   });
  //   return fields;
  // }

  get survey(): SurveyResource {
    return surveyModule.find(this.surveyId);
  }

  async created() {
    await this.loadSurveyFields();
  }

  async loadSurveyFields(): Promise<void> {
    // don't waste an API call on a temporary pageId
    if (this.pageId.substr(0, 3) === 'new') {
      return;
    }
    this.loading = true;
    try {
      await surveyFieldModule.loadMulti({
        append: true,
        filters: {pageId: this.pageId},
      });
    } catch (err) {
      console.error(err.response.data);
    }
    this.loading = false;
  }

  getSurveyFieldsInQuestionCollection(questionCollectionId: string) {
    return this.surveyFieldsAll.filter(
      field =>
        String(field.attributes.questionCollectionId) ===
          String(questionCollectionId) && field.attributes.questionId
    );
  }

  closeQuestionCollectionAddToSurveyDialog(message?: string) {
    this.questionCollectionAddToSurveyDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
    this.surveyFields.map((field): boolean =>
      surveyFieldModule.deleteNoPersist(field.id)
    );
    this.loadSurveyFields();
  }

  closeSurveyFieldDialog(message?: string) {
    this.surveyFieldDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  async deleteField(fieldId: string): Promise<void> {
    this.fieldId = fieldId;
    const field = surveyFieldModule.find(fieldId);
    const result = confirm(
      `Are you sure you want to delete the field labelled ${field.attributes.label}?`
    );
    if (result) {
      await surveyFieldModule.delete(fieldId);
    }
  }

  async save() {
    try {
      if (this.fieldId === null) {
        throw Error('Field ID is null');
      }
      await surveyFieldModule.save(this.fieldId);
      this.$emit('close', 'Survey field saved successfully');
    } catch (err) {
      this.alert = {
        message: 'Could not save survey field',
        type: 'error',
      };
    }
  }

  async showQuestionCollectionAddToSurveyDialog(fieldId: string) {
    this.fieldId = fieldId;
    // if (fieldId === 'new') {
    //   await surveyFieldModule.loadById('new');
    //   let field = surveyFieldModule.find('new');
    //   field.attributes.pageId = parseInt(this.pageId);
    //   field.attributes.surveyId = parseInt(this.surveyId);
    //   field = await surveyFieldModule.save('new');
    //   this.fieldId = field.id;
    //   this.newField = true;
    // } else {
    //   this.newField = false;
    // }
    this.questionCollectionAddToSurveyDialogShown = true;
  }

  async showSurveyFieldDialog(fieldId: string) {
    this.fieldId = fieldId;
    if (fieldId === 'new') {
      await surveyFieldModule.loadById('new');
      let field = surveyFieldModule.find('new');
      field.attributes.pageId = parseInt(this.pageId);
      field.attributes.surveyId = parseInt(this.surveyId);
      field = await surveyFieldModule.save('new');
      this.fieldId = field.id;
      this.newField = true;
    } else {
      this.newField = false;
    }
    this.surveyFieldDialogShown = true;
  }

  async deleteAllFieldsInQuestionCollection(qcId: number) {
    const fieldIds = this.surveyFieldsAll
      .filter(field => field.attributes.questionCollectionId === qcId)
      .map(field => String(field.id));
    console.log(fieldIds);
    const result = confirm(
      `Are you sure you want to delete all ${fieldIds.length} fields in this collection?`
    );
    if (result) {
      await Promise.all(fieldIds.map(id => surveyFieldModule.delete(id)));
    }
  }

  async pageMoved(): Promise<void> {
    const orderedIds = this.surveyFields.map(field => field.id);

    await surveyFieldModule.action({
      attributes: {
        action: 'reorder',
        surveyId: this.surveyId,
        surveyPageId: this.pageId,
        ids: orderedIds,
      },
    });

    this.snackbar.visible = true;
    this.snackbar.message = 'Survey field order saved.';
  }

  async restoreQuestionCollection(questionCollectionId: string): Promise<void> {
    await surveyFieldModule.action({
      attributes: {
        action: 'restore-question-collection',
        surveyId: this.surveyId,
        questionCollectionId: questionCollectionId,
      },
    });

    this.snackbar.visible = true;
    this.snackbar.message = 'Restoring question collection order saved.';
    await surveyFieldModule.reset();
    await this.loadSurveyFields();
  }
}
