






























































import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage, Snackbar, SurveyFieldAttributes} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {mdiTrashCan} from '@mdi/js';
import {surveyFieldItemModule} from '@/store/modules/surveyFieldItemModule';
import {SurveyFieldItemResource} from '@/interfaces/resources/SurveyFieldItemResource';
import SurveyPageAutocomplete from '@/components/surveys-edit/SurveyPageAutocomplete.vue';
import DialogAlertDense from '@/components/ui/DialogAlertDense.vue';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import {cloneDeep, isEqual} from 'lodash';
import {surveyFieldModule} from '@/store/modules';
import {QuestionType} from '@/enums';

@Component({
  components: {
    NotificationBar,
    DialogAlertDense,
    SurveyPageAutocomplete,
    RequiredLabel,
    DialogAlert,
  },
})
export default class SurveyFieldDialogItemListItem extends Vue {
  @Prop() private surveyFieldItem!: SurveyFieldItemResource;
  @Prop() private surveyFieldId!: number;
  @Prop({default: ''}) private labelSuffix!: string;
  private alert: AlertMessage | null = null;
  private iconDelete = mdiTrashCan;
  private originalItem: SurveyFieldItemResource | null = null;
  private snackbar: Snackbar = {visible: false, message: ''};
  private unsaved = false;
  private questionType = QuestionType;

  get deleting(): boolean {
    return surveyFieldItemModule.deleting;
  }

  get deletingIds(): string[] {
    return surveyFieldItemModule.deletingIds;
  }

  get surveyField(): SurveyFieldAttributes {
    console.log(this.surveyFieldItem);
    return surveyFieldModule.find(String(this.surveyFieldId)).attributes;
  }

  get listing(): boolean {
    return surveyFieldItemModule.listing;
  }

  get saving(): boolean {
    return surveyFieldItemModule.saving;
  }

  get savingIds(): string[] {
    return surveyFieldItemModule.savingIds;
  }

  async deleteItem(fieldId: string) {
    this.$emit('deleteItem', fieldId);
    await surveyFieldItemModule.delete(fieldId);
  }

  created() {
    this.originalItem = cloneDeep(this.surveyFieldItem);
  }

  async save(itemId: string) {
    // just in case this is a number
    itemId = String(itemId);
    try {
      await surveyFieldItemModule.save(itemId);
      this.originalItem = cloneDeep(this.surveyFieldItem);
      this.unsaved = false;
      this.alert = null;
      this.snackbar.message = 'Item saved';
      this.snackbar.visible = true;
    } catch (err) {
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.alert = {
        message: error ? error : 'Error',
        type: 'error',
      };
    }
  }
  input() {
    this.surveyFieldItem.attributes.value = this.surveyFieldItem.attributes.label;
    this.unsaved = !isEqual(this.originalItem, this.surveyFieldItem);
  }
}
