

































import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage, SurveyFieldAttributes} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {mdiPlus} from '@mdi/js';
import {surveyFieldItemModule} from '@/store/modules/surveyFieldItemModule';
import {SurveyFieldItemResource} from '@/interfaces/resources/SurveyFieldItemResource';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import DialogAlertDense from '@/components/ui/DialogAlertDense.vue';
import SurveyFieldDialogItemListItem from '@/components/surveys-edit/SurveyFieldDialogItemListItem.vue';
import {surveyFieldModule} from '@/store/modules';
import {QuestionType} from '@/enums';
import {orderBy} from 'lodash';

@Component({
  components: {
    SurveyFieldDialogItemListItem,
    DialogAlertDense,
    NotificationBar,
    RequiredLabel,
    DialogAlert,
  },
})
export default class SurveyFieldDialogItemList extends Vue {
  @Prop() private surveyFieldId!: string;
  @Prop() private surveyFieldType!: string;
  @Prop({default: false}) private newItem!: false;
  private alert: AlertMessage | null = null;
  private iconAdd = mdiPlus;
  private fieldItemCreating = false;
  private questionType = QuestionType;

  get listing(): boolean {
    return surveyFieldItemModule.listing;
  }

  get saving(): boolean {
    return surveyFieldItemModule.saving;
  }

  get surveyFieldItems(): SurveyFieldItemResource[] {
    if (this.surveyField.type === QuestionType.RatingRadio) {
      return orderBy(
        surveyFieldItemModule.list.filter(item => item.id !== 'new'),
        ['id'],
        ['asc']
      );
    }
    return surveyFieldItemModule.list.filter(item => item.id !== 'new');
  }

  get surveyField(): SurveyFieldAttributes {
    return surveyFieldModule.find(this.surveyFieldId).attributes;
  }

  deleteItem(itemId: string) {
    this.$emit('deleteItem', itemId);
  }

  async created(): Promise<void> {
    try {
      await surveyFieldItemModule.loadMulti({
        filters: {surveyFieldId: this.surveyFieldId},
      });
    } catch (err) {
      if (err.response && err.response.data) {
        console.error(err.response.data);
      } else {
        console.error(err);
      }
    }

    if (
      this.surveyFieldItems.length === 0 &&
      this.surveyField.type === QuestionType.RatingRadio
    ) {
      // [...Array(11).keys()].forEach(
      //   async key => await this.addItem(String(key))
      // );
      // for (let i = 0; i < 11; i++) {
      //   await this.addItem(String(i));
      // }
      // await Promise.all(
      //   [...Array(11).keys()].map(async key => await this.addItem(String(key)))
      // );
      for (let i = 0; i < 11; i++) {
        await this.addItem(String(i));
      }
    }
  }

  async addItem(label?: string) {
    this.fieldItemCreating = true;
    try {
      await surveyFieldItemModule.loadById('new');
      const field = surveyFieldItemModule.find('new');
      field.attributes.surveyFieldId = parseInt(this.surveyFieldId);
      if (label) {
        field.attributes.label = label;
        field.attributes.value = label;
      }

      const item = await surveyFieldItemModule.save('new');
      const surveyField = surveyFieldModule.find(this.surveyFieldId);
      surveyField.attributes.items.push(item.attributes);
      this.fieldItemCreating = false;
      this.alert = null;
    } catch (err) {
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.alert = {
        message: error ? error : 'Error',
        type: 'error',
      };
    }
  }

  touched() {
    console.log('touched');
  }
}
