
































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import ButtonCancelAction from '@/components/ui/ButtonCancelAction.vue';
import ButtonSaveAction from '@/components/ui/ButtonSaveAction.vue';
import {EditableFieldSaveEvent} from '@/interfaces';
@Component({
  components: {ButtonSaveAction, ButtonCancelAction, ButtonEditAction},
})
export default class EditableField extends Vue {
  @Prop({default: ''}) private value!: string;
  @Prop({default: false}) private saving!: boolean;
  @Prop({default: false}) private fullWidth!: boolean;
  @Prop({default: false}) private required!: boolean;
  @Prop() private id!: string;
  private cols = 6;
  private editing = false;
  private tempValue = '';

  created() {
    this.tempValue = this.value;
    this.cols = this.fullWidth ? 12 : 6;
  }

  cancel() {
    this.$emit('cancel');
    this.editing = false;
    // reset temp value
    this.tempValue = this.value;
  }

  save() {
    // only save if value is new
    if (this.value === this.tempValue) {
      this.editing = false;
      return;
    }

    this.$emit('update:value', this.tempValue);

    const event: EditableFieldSaveEvent = {
      id: this.id,
      value: this.tempValue,
      originalValue: this.value,
    };

    this.$emit('save', event);
    this.editing = false;
  }

  input(value: string) {
    this.tempValue = value;
  }
}
